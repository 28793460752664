import * as React from "react"
import classnames from "classnames"

export interface CheckboxProps {
    className?: string
    label?: string
    isChecked?: boolean
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
    return <div className={classnames("checkbox", props.className)}>
        <div className="c-circle">
            {
                props.isChecked && <div className="c-circle-inner"></div>
            }
        </div>
        <div className="c-label">{props.label}</div>
    </div>
}