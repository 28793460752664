import Axios from "axios"

export interface OrdersForCheckInResponse {
    orders: {
        fullname: string
        total: string
        pickupDate: string
        orderDate: string
        checkInUrl: string
        token: string
        checkNumber: string
        status: string
        isCheckedIn: boolean
        store: {
            storeId:string   
            street:string   
            city:string   
            state:string   
            zip:string   
        }
    }[]
    statusDescription: string
}
export const fetchOrdersForCheckIn = (locationId: string, phoneNumber: string) => {
    return Axios.get<OrdersForCheckInResponse>(`/order/getOrderForCheckin?locationId=${locationId}&phoneNumber=${phoneNumber}`)
}

export interface PostReadyForPickupResponse {
    isSuccess: boolean
    description: string
}
export const postReadyForPickup = (token: string) => {
    return Axios.post<PostReadyForPickupResponse>(`/order/readyForPickup`, { token })
}