import * as React from "react"
import { Partials, Components } from "shared"
import { HashRouter as Router, Switch, Route, useHistory } from "react-router-dom"
import Search from "./views/search"
import Orders from "./views/orders"
import { Provider, useDispatch } from "react-redux"
import { store } from "./reducers"
import { RootState } from "./reducers/index.reducer"
import { CheckInResult, InstantCheckInResult } from "./views/check.in.result"
import { instantReadyForPickup } from "./reducers/checkin.reducer"

interface ApplicationProps {
    authCheckin?: string
}

export const Application: React.FunctionComponent<ApplicationProps> = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (props.authCheckin) {
            dispatch(instantReadyForPickup(props.authCheckin))
        }
    }, [])

    const routes = React.useMemo(() => {
        if (props.authCheckin)
            return <Route path="/">
                <InstantCheckInResult />
            </Route>

        return <>
            <Route path={["/", "/search"]} exact >
                <Search />
            </Route>
            <Route path="/orders">
                <Orders />
            </Route>
            <Route path="/results">
                <CheckInResult />
            </Route>
        </>
    }, [])

    return <Router>
        <Components.Layout.Wrapper>
            <Switch>
                {routes}
            </Switch>
        </Components.Layout.Wrapper>
    </Router>

}

export default (props: ApplicationProps) => <Provider store={store}>
    <Application {...props} />
</Provider>