import Axios from "axios"

interface Store {
    storeId?: string
    name?: string
    state?: string
    city?: string
    zip?: string
    street?: string
    description?: string
    publicStoreId?: string
    
}

export const getStoreByPublicId = (locationId: string) => {
    return Axios.get<Store>(`/store/getByPublicId/${locationId}`)
}
