import * as React from "react"
import { Components, Infrastructure } from "shared"
import { useHistory, Link, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { searchOrders, ordersSlice, searchFailedStatus } from "../reducers/order.reducer"
import { RootState } from "../reducers/index.reducer"
import { phoneNumberSlice, phoneNumberValidation } from "../reducers/phone.reducer"


interface Props {
}

export default (props: Props) => {
    const searchStatusMessage = useSelector(searchFailedStatus)
    const validation = useSelector(phoneNumberValidation);
    const isSearching = useSelector<RootState, boolean>((state) => state.orders.isFetching);
    const dispatch = useDispatch()


    const handleSubmit = () => {
        dispatch(searchOrders())
    }

    return <>
        <Components.Layout.Content aria-label="customer contact form">
            <Components.Layout.ContentDescription> {searchStatusMessage || "Please enter the phone number used when placing your order"}</Components.Layout.ContentDescription>
            <PhoneNumberTextbox />
        </Components.Layout.Content>
        <Components.Layout.Footer>
            <button className="button" disabled={!validation.isValid} onTouchStart={handleSubmit} onClick={handleSubmit}>{isSearching ? "Searching..." : "Find Orders"}</button>
        </Components.Layout.Footer>
    </ >
}

const PhoneNumberTextbox = () => {
    const phoneNumber = useSelector<RootState, string>(state => state.phoneNumber);
    const validation = useSelector(phoneNumberValidation);
    const dispatch = useDispatch();

    return <Components.Textbox label="Phone number"
        validation={validation}
        placeholder={Infrastructure.phoneMaskPlaceHolder}
        value={phoneNumber} name="phoneNumber"
        onChange={(phoneNumber) => dispatch(phoneNumberSlice.actions.changed(Infrastructure.phoneMask(phoneNumber)))} />
}