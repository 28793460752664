import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit"
import Axios from "axios";
import { ThunkApiConfig } from ".";
import { Infrastructure, Server } from "shared";
import { RootState } from "./index.reducer";



export const searchOrders = createAsyncThunk<Server.Orders.OrdersForCheckInResponse, void, ThunkApiConfig>("order/search", async (args, { getState, extra }) => {
    // var response = await server.orders.fetchOrdersForCheckIn(getState().store.publicStoreId, unMaskPhone(getState().phoneNumber));
    var response = await Server.Orders.fetchOrdersForCheckIn(null, Infrastructure.unMaskPhone(getState().phoneNumber));

    if (response.data.orders?.length > 0)
        extra.history.push("/orders")

    return response.data;
})

export const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        entities: [] as Server.Orders.OrdersForCheckInResponse["orders"],
        status: null as string,
        isFetching: false
    },
    reducers: {
        loaded: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
        reset: (state) => ({ ...state, entities: [], status: null })
    },
    extraReducers: builder => {
        builder.addCase(searchOrders.pending, () => {
            return {
                entities: [],
                status: null,
                isFetching: true
            }
        })
        
        builder.addCase(searchOrders.fulfilled, (state, { payload }) => {
            return {
                entities: payload?.orders,
                status: payload?.statusDescription || null,
                isFetching: false
            }
        })

        builder.addCase(searchOrders.rejected, (state, { payload }) => {
            return {
                entities: [],
                status: null,
                isFetching: false
            }
        })
    }
})



export interface Order {
    fullname: string
    total: string
    pickupDate: Date
    orderDate: Date
    checkInUrl: string
    checkNumber: string
    token: string
    status: string
    isCheckedIn: boolean
    store: Server.Orders.OrdersForCheckInResponse["orders"][0]["store"]
}
export const selectOrders = createSelector((state: RootState) => state.orders?.entities || [], (orders) => {
    return orders?.map<Order>((item) => ({
        ...item,
        pickupDate: new Date(Date.parse(item.pickupDate)),
        orderDate: new Date(Date.parse(item.orderDate))
    }))
})

export const searchFailedStatus = createSelector((state: RootState) => state.orders, (orders) => {
    return orders.entities.length == 0 && orders.status?.length > 0 ? orders.status : null
})