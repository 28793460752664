
const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const phoneRegExp = new RegExp("/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im");
export const isRequired = (text: string | number | Date, message = "Required"): ValidationResult => {
    var isValid = text != void (0);
    if (!isValid)
        return {
            isValid: false,
            message: message
        }

    if (typeof (text) == "string") {
        return {
            isValid: text?.trim()?.length > 0,
            message: message
        }
    }
    if (typeof (text) == "number") {
        return {
            isValid: text >= 0 || text <= 0,
            message: message
        }
    }
    if (text instanceof Date) {
        return {
            isValid: text?.getTime() > 0,
            message: message
        }
    }

    return {
        isValid: true,
        message: message
    }
}


export const isEmail = (text: string, message = "Required"): ValidationResult => {
    return {
        isValid: emailRegExp.test(text),
        message: "Invalid email address"
    }
}


export const validateAll = (...validators: ValidationResult[]): ValidationResult => {
    var validationError = validators?.find(x => !x.isValid)

    if (!validationError)
        return {
            isValid: true,
            message: null
        }

    return validationError
}

export const phoneMask = (text: string) => {
    var digits = text.replace(/\D/g, '');
    if (digits?.length == 0)
        return "";
    if (digits.length <= 3)
        return `(${digits.substr(0, 3)}`;

    if (digits.length <= 6)
        return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}`;

    return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`
}
export const phoneMaskPlaceHolder = "(xxx) xxx-xxxx"
export const unMaskPhone = (text: string) => {
    return text.replace(/\D/g, '');
}
export const isPhone = (text: string): ValidationResult => {
    return {
        isValid: text?.replace(/\D/g, '')?.length == 10,
        message: "Invalid phone number"
    }
}


const datetimeParts = {
    date: "dd",
    month: "MM",
    year: "yyyy",
    hours: "hh",
    minutes: "mm",
    seconds: "ss",
    ampm: "a"
}

export const datetimeFormats = {
    default: `${datetimeParts.month}/${datetimeParts.date}/${datetimeParts.year}`,
    dateTime: `${datetimeParts.month}/${datetimeParts.date}/${datetimeParts.year} ${datetimeParts.hours}:${datetimeParts.minutes} ${datetimeParts.ampm}`,
    time: `${datetimeParts.hours}:${datetimeParts.minutes} ${datetimeParts.ampm}`,
}

export const formatDate = (value: Date, format = datetimeFormats.default) => {
    if (!value)
        return "";

    var year = value?.getFullYear().toString()?.padStart(datetimeParts.year.length, '0');
    var month = (value?.getMonth() + 1).toString()?.padStart(datetimeParts.month.length, '0');
    var date = value?.getDate().toString()?.padStart(datetimeParts.date.length, '0');
    var fullhours = value?.getHours();
    var hours = (fullhours > 12 ? fullhours - 12 : fullhours).toString()?.padStart(datetimeParts.hours.length, '0');
    var minutes = value?.getMinutes().toString()?.padStart(datetimeParts.minutes.length, '0');;
    var ampm = fullhours > 11 ? "PM" : "AM"

    return format.replace(datetimeParts.year, year)
        .replace(datetimeParts.month, month)
        .replace(datetimeParts.date, date)
        .replace(datetimeParts.hours, hours)
        .replace(datetimeParts.minutes, minutes)
        .replace(datetimeParts.ampm, ampm);
}

export const parseDate = (valueStr: string, format = datetimeFormats.default) => {
    if (!valueStr || valueStr.length != format.length)
        return null;

    var indexes = [format.indexOf(datetimeParts.month), format.indexOf(datetimeParts.date), format.indexOf(datetimeParts.year)];
    if (indexes.some(x => x < 0))
        return null;

    var month = parseInt(valueStr.substr(indexes[0], datetimeParts.month.length)?.padStart(datetimeParts.month.length, '0'))
    var date = parseInt(valueStr.substr(indexes[1], datetimeParts.date.length)?.padStart(datetimeParts.date.length, '0'))
    var year = parseInt(valueStr.substr(indexes[2], datetimeParts.year.length)?.padStart(datetimeParts.year.length, '0'))

    if (month >= 0 && date >= 0 && year >= 0)
        return new Date(year, month - 1, date)

    return null;
}