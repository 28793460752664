import * as React from "react"
import classnames from "classnames"
import { ProgressPlugin } from "webpack";
import { Partials } from "shared";
export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string
}

export const Wrapper: React.FunctionComponent<LayoutProps> = (props) => {
    const { className, title, ...restProps } = props;
    return <div className="layout-wrapper">
        <div className="background">
            <div className="background-filter"></div>
        </div>
        <div className={classnames("layout", className)} {...restProps}>
            {props.children}
            <footer className="habit-links" aria-label="additional links">
                <ul>
                    <li> <a href="https://www.habitburger.com/contact-us/" aria-label="contact us link">Contact Us</a></li>
                    <li> <a href="https://www.habitburger.com/privacy-policy/" aria-label="privacy policy link">Privacy Policy</a></li>
                </ul>
            </footer>
        </div>
    </div>
}


export const Content: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const { className, ...restProps } = props;
    return <section className={classnames("layout-content", className)}>
        <header className="layout-header">
            <h1 className="site-header">{appConfig.title}</h1>
            <Partials.HabitLogo />
        </header>
        {props.children}
    </section>
}
export const ContentDescription: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const { className, ...restProps } = props;
    return <section className={classnames("layout-content-description", className)}>
        {props.children}
    </section>
}

export const Footer: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const { className, ...restProps } = props
    return <div className={classnames("layout-footer", className)} {...restProps}>
        {props.children}
    </div>
}
// }
// export const Block: React.FunctionComponent<BlockProps> = (props) => {
//     const { className, blank, ...restProps } = props
//     return <div className={classnames("l-block", { "l-b-blank": props.blank }, className)} {...restProps} >
//         {props.children}
//     </div >
// }
