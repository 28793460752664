import { configureStore, createReducer, createAction, createSlice, PayloadAction, CaseReducer, createAsyncThunk, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit"
import { combinedReducer, RootState } from "./index.reducer"
import { createHashHistory, History } from "history"

export interface ThunkApiConfig {
    dispath: typeof store.dispatch
    state: RootState
    extra: {
        history: History
    }
    rejectValue: any
}

export const store = configureStore({
    reducer: combinedReducer,
    middleware: getDefaultMiddleware({
        thunk: {
            extraArgument: {
                history: createHashHistory()
            }
        }
    })
})

