import * as React from "react"
import classnames from "classnames"

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const { className, ...restProps } = props;

    return <button className={classnames("btn", className)} {...restProps}>
        {props.children}
    </button>
}
