import * as React from "react"
import classnames from "classnames"

export interface RadioButtonsProps {
    name: string
    className?: string
    label?: string
    isChecked?: boolean
    options: { itemText: string, itemValue: any }[]
    value: any
    onChange(itemValue: any)
}
export const RadioButtons: React.FunctionComponent<RadioButtonsProps> = (props) => {
    return <fieldset className={classnames("radio-button-group", props.className)} name={props.name}>
        <legend className="label">{props.label}</legend>
        <div className="rbg-content">
            {
                props.options.map((item, index) =>
                    <label className={classnames("radio-button", { "r-checked": props.value == item.itemValue })}>
                        <input type="radio" name={props.name} checked={props.value == item.itemValue} onChange={(v) => props.onChange(item.itemValue)} />
                        <div className="c-circle">
                            {
                                props.value == item.itemValue && <div className="c-circle-inner"></div>
                            }
                        </div>
                        {item.itemText}
                    </label>
                )
            }
        </div>
    </fieldset>
}