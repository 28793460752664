import * as React from "react"

export interface StoreInfoProps {
    data: {
        storeId: string
        street: string
        city: string
        state: string
        zip: string
    }
}

export const StoreInfo: React.FunctionComponent<StoreInfoProps> = (props) => {
    return <div className="store-location" aria-label="store-information">
        <div className="location-icon">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512"  >
                <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z" />
            </svg>
        </div>
        <div className="location-info">
            <div className="store-id" aria-label="store-number" >STORE #{props.data?.storeId}</div>
            <div className="store-street" aria-label="store-street">{`${props.data?.street}`}</div>
            <div className="store-city" aria-label="store-city">{`${props.data?.city}, ${props.data?.state} ${props.data?.zip}`}</div>
        </div>
    </div>
}