import * as React from "react"
import classnames from "classnames"

export interface TextboxProps {
    type?: "text" | "number"
    className?: string
    value?: string
    onChange?(value: string)
    validation?: ValidationResult
    name?: string
    label?: string
    hide?: boolean
    placeholder?: string
}
export const Textbox: React.StatelessComponent<TextboxProps> = (props) => {
    if (props.hide)
        return null;

    return <label className="label" >
        <div className="label-text">{props.label}</div><div className={classnames("textbox", props.className, { "invalid": props?.validation?.isValid == false })}>
            <input type={props.type} placeholder={props.placeholder} name={props.name} value={props.value} onChange={(value) => props?.onChange?.(value.currentTarget.value)} />
            {
                props.validation?.isValid == false && <div className="validation-error">{props.validation.message}</div>
            }
        </div>
    </label>
}

Textbox.defaultProps = {
    type: "text"
}