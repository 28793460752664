import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Server } from "shared";
import { ThunkApiConfig } from ".";


export const instantReadyForPickup = createAsyncThunk<Server.Orders.PostReadyForPickupResponse & { token: string }, string, ThunkApiConfig>("order/checkin", async (token, { getState, extra }) => {
    var result = await Server.Orders.postReadyForPickup(token);

    return {
        token,
        ...result?.data,
    };
})

export const readyForPickup = createAsyncThunk<Server.Orders.PostReadyForPickupResponse & { token: string }, string, ThunkApiConfig>("order/checkin", async (token, { getState, extra }) => {
    var result = await Server.Orders.postReadyForPickup(token);

    if (result.data.isSuccess)
        extra.history.push("/results")

    return {
        token,
        ...result?.data,
    };
})

const defaultState = {
    token: null,
    isSuccess: false,
    description: null,
    isFailed: false
}
export const checkInSlice = createSlice({
    name: "checkIn",
    initialState: defaultState,
    reducers: {
        reset: (state) => ({ ...defaultState })
    },
    extraReducers: builder => {
        builder.addCase(readyForPickup.pending, (state, { payload, meta }) => {
            return {
                ...state,
                token: meta.arg
            }
        })
        builder.addCase(readyForPickup.fulfilled, (state, { payload }) => {
            return {
                ...state,
                token: null,
                isSuccess: !!payload?.isSuccess,
                description: payload?.description
            }
        })
        builder.addCase(readyForPickup.rejected, (state, { payload }) => {
            return {
                ...state,
                token: null,
                isFailed: true,
                isSuccess: false,
                description: "Sorry, looks like we're having technical difficulties, please try again, if issue persist please try again or check in with the store."
            }
        })
    }
})

