import Axios from "axios"

export interface SubmitContract {
    publicStoreId: string
    storeId: string
    firstName: string
    lastName: string
    purchaseDate: Date
    contactType: string
    phoneNumber: string
    email: string
    street: string
    city: string
    state: string
    zip: number
}
export interface SubmitResponse {
    message: string
}

export const submit = (contract: SubmitContract) => {
    return Axios.post<SubmitResponse>(`/covidtracking/submit`, contract)
}
