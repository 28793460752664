import * as React from "react"
import { ProgressPlugin } from "webpack"

export interface IconProps extends React.SVGProps<SVGSVGElement> {
    size?: string
}
export const Bag: React.FunctionComponent<IconProps> = (props) => {
    const { size, xmlns, width, height, viewBox, ...svgProps } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" width={size || width || 48} height={size || height || 48} viewBox={"0 0 48 48" || viewBox} {...props}>
        <g fill="none" fillRule="evenodd" className="bag-2">
            <path fill="rgba(180, 37, 46, 0.68)" fillRule="nonzero" stroke="#B4252E" d="M39.6959865,36.9853125 L37.9098927,14.4767969 C37.7990334,13.0265625 36.5720802,11.8910156 35.1179396,11.8910156 L31.5895021,11.8910156 L31.5895021,17.1189062 C31.5895021,17.7663281 31.0654396,18.2903906 30.4180177,18.2903906 C29.7713771,18.2903906 29.2465334,17.7663281 29.2465334,17.1189062 L29.2465334,11.8910156 L18.4691115,11.8910156 L18.4691115,17.1189062 C18.4691115,17.7663281 17.9442677,18.2903906 17.2976271,18.2903906 C16.6502052,18.2903906 16.1261427,17.7663281 16.1261427,17.1189062 L16.1261427,11.8910156 L12.5977052,11.8910156 C11.1435646,11.8910156 9.91661149,13.0265625 9.80575211,14.4736719 L8.01887711,36.9884375 C7.88067399,38.7917188 8.50622086,40.5864063 9.73465836,41.9125 C10.963174,43.2385156 12.7047365,44 14.5127052,44 L33.2030177,44 C35.0109865,44 36.752549,43.2385156 37.9810646,41.9124219 C39.2095021,40.5863281 39.835049,38.7916406 39.6959865,36.9853125 Z" className="bag" />
            <path fill="#FFF" fillRule="nonzero" stroke="#B4252E" d="M23.8578615,4 C19.5945021,4 16.1262209,7.4690625 16.1262209,11.7324219 L16.1262209,11.8909375 L18.4691896,11.8909375 L18.4691896,11.7324219 C18.4691896,8.76078125 20.886299,6.34289062 23.8579396,6.34289062 C26.8295802,6.34289062 29.2466896,8.76078125 29.2466896,11.7324219 L29.2466896,11.8909375 L31.5895802,11.8909375 L31.5895802,11.7324219 C31.5895802,7.4690625 28.1212209,4 23.8578615,4 Z" className="handle" />
        </g>
    </svg>
}