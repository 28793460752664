import * as React from "react";
import * as ReactDOM from "react-dom"
import Application from "./index"


window.renderApplication = (config) => {
    document.title = "Curbside Check In";
    window.appConfig = {
        title: "Curbside Check In"
    }
    var div = document.createElement("div");

    ReactDOM.render(<Application authCheckin={config.checkToken} />, div);

    document.body.append(div);
}
