import * as React from "react"
import { Components } from "shared"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../reducers/index.reducer"
import { Redirect, useHistory } from "react-router-dom"
import { ordersSlice } from "../reducers/order.reducer"
import { checkInSlice } from "../reducers/checkin.reducer"

interface Props {
}
export const CheckInResult: React.FC<Props> = (props) => {
    const message = useSelector((state: RootState) => state.checkIn.description)
    const history = useHistory();
    const dispatch = useDispatch();

    if (!message)
        return <Redirect to="/search" push />

    const handleClickBack = () => {
        dispatch(ordersSlice.actions.reset());
        dispatch(checkInSlice.actions.reset());
        history.push("/");
    }

    return <>
        <Components.Layout.Content className="checkin-result">
            <Components.Layout.ContentDescription >{message}</Components.Layout.ContentDescription>
        </Components.Layout.Content>
        <Components.Layout.Footer>
            <button className="button" onTouchStart={handleClickBack} onClick={handleClickBack}>Back</button>
        </Components.Layout.Footer>
    </>
}

export const InstantCheckInResult: React.FC<Props> = (props) => {
    const message = useSelector((state: RootState) => state.checkIn.description)
    const isFailed = useSelector((state: RootState) => state.checkIn.isFailed)

    return <>
        <Components.Layout.Content className="checkin-result">
            <Components.Layout.ContentDescription >{message ? message : "Processing..."}</Components.Layout.ContentDescription>
        </Components.Layout.Content>
        {
            isFailed && <Components.Layout.Footer>
                <button className="button" onTouchStart={() => { location.reload() }} onClick={() => { location.reload() }}>Try Again</button>
            </Components.Layout.Footer>
        }

    </>
}