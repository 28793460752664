import { configureStore, createReducer, createAction, createSlice, PayloadAction, CaseReducer, createAsyncThunk, combineReducers } from "@reduxjs/toolkit"

// import { storeSlice } from "./store.reducer"
import { phoneNumberSlice } from "./phone.reducer"
import { ordersSlice } from "./order.reducer"
import { checkInSlice } from "./checkin.reducer"


export const combinedReducer = combineReducers({
    // store: storeSlice.reducer,
    phoneNumber: phoneNumberSlice.reducer,
    orders: ordersSlice.reducer,
    checkIn: checkInSlice.reducer
})

export type RootState = ReturnType<typeof combinedReducer>
