import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./index.reducer";
import { Infrastructure } from "shared";

export const phoneNumberSlice = createSlice({
    name: "phoneNumber",
    initialState: "",
    reducers: {
        changed: (state, payload) => payload.payload
    }
})

export const phoneNumberValidation = createSelector((state: RootState) => state.phoneNumber, (phoneNumber): ValidationResult => {
    return Infrastructure.isPhone(phoneNumber);
})