import * as React from "react"
import classnames from "classnames"
import { parseDate, formatDate } from "../infrastructure";

export interface DatePickerProps {
    className?: string
    value?: Date
    onChange?(value: Date)
    validation?: ValidationResult
}

const dateFormat = 'yyyy-MM-dd';
export const DatePicker:React.FunctionComponent<DatePickerProps> = (props) => {
    const [value, setValue] = React.useState<string>(formatDate(props?.value, dateFormat));

    React.useEffect(() => {
        var date = parseDate(value, dateFormat) || parseDate(value);

        if (date)
            props.onChange(date);
    }, [value])

    const handleBlur = React.useCallback(() => {
        setValue(formatDate(props.value, dateFormat))
    }, [props?.value])

    return <div className={classnames("datepicker", props.className, { "invalid": props?.validation?.isValid == false })}>
        <input type="date"
            placeholder={dateFormat}
            value={value}
            onBlur={handleBlur}
            onChange={(value) => {
                setValue(value.currentTarget.value)
            }} />
        {
            props.validation?.isValid == false && <div className="validation-error">{props.validation.message}</div>
        }
    </div>
}