import * as React from "react"
import { Components, Infrastructure, Partials } from "shared"
import { useHistory, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../reducers/index.reducer"
import { ordersSlice, Order, selectOrders } from "../reducers/order.reducer"
import { instantReadyForPickup } from "../reducers/checkin.reducer"

interface CustomerData {
    phoneNumber?: string
}

interface Props {
}
export default (props: Props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const orders = useSelector(selectOrders)
    const status = useSelector<RootState, string>(state => state.orders.status)
    const isCheckedIn = useSelector<RootState, boolean>(state => state.checkIn.isSuccess)
    const checkedMessage = useSelector<RootState, boolean>(state => state.checkIn.description)

    const handleClickBack = () => {
        dispatch(ordersSlice.actions.reset());
    }

    const handleToContactStore = () => {
        history.push("/contactStore");
    }

    if (orders.length == 0)
        return <Redirect to="/search" push />

    return <>
        <Components.Layout.Content>
            {
                checkedMessage != null
                    ? <Components.Layout.ContentDescription className="checkin-result">
                        {checkedMessage}
                    </Components.Layout.ContentDescription>
                    : <Components.Layout.ContentDescription className="alert-message">
                        {status}
                    </Components.Layout.ContentDescription>
            }
            {
                orders.map(item => <OrderItem key={item.token} data={item} />)
            }
        </Components.Layout.Content>
        <Components.Layout.Footer>
            <button className="button" onTouchStart={history.goBack} onClick={handleClickBack}>Back</button>
        </Components.Layout.Footer>
    </ >
}

interface OrderItemProps {
    data: Order
}
const OrderItem: React.FunctionComponent<OrderItemProps> = (props) => {
    const dispatch = useDispatch();
    const isProcessing = useSelector((state: RootState) => state.checkIn.token == props.data.token)
    const handleClickCheckIn = React.useCallback(() => {
        dispatch(instantReadyForPickup(props.data.token))
    }, [props.data?.token])

    return <>
        <Partials.StoreInfo data={props.data.store}></Partials.StoreInfo>
        <br />
        <div className="order-item">

            <div className="oi-icon">
                <Components.Icon.Bag size="36" />
            </div>
            <div className="oi-content">
                <div className="oi-header">{props.data.fullname}</div>
                <div className="oi-highight">Order: {props.data.checkNumber}</div>
                <div>Order Time: {Infrastructure.formatDate(props.data.orderDate, Infrastructure.datetimeFormats.time)}</div>
                <div>Pick up Time: {Infrastructure.formatDate(props.data.pickupDate, Infrastructure.datetimeFormats.time)}</div>
                <div>Order Total: {props.data.total}</div>
                <div className="oi-highight oi-alert">{props.data.status}</div>
            </div>
            <div className="oi-side">
                {
                    !props.data.isCheckedIn && <Components.Button disabled={isProcessing} onClick={handleClickCheckIn}>{isProcessing ? "Processing..." : "Check In"}</Components.Button>
                }
            </div>
        </div>
    </>
}
